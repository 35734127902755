import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortService{

  public iconCode = `../../assets/media/icons/duotune/arrows/arr004.svg`;
  public columnName = '';

  // columns = [
  //   { nome: "asc" },
  //   { cognome: "asc" },
  //   { residenza: "asc" },
  //   { email: "asc" },
  //   { cellulare: "asc" },
  //   { user_type: "asc" },
  // ]

  columns: { [key: string]: string } = {
    nome: "",
    cognome: "",
    luogo_residenza: "",
    email: "",
    cellulare: "",
    user_type: "",
  }

  constructor() { }

  setIconStatus(dataOrder: 'asc' | 'desc' | '', dataName: string) {
    this.columns[dataName] = dataOrder;
  }

  setIconColumn(dataName: string) {
    this.columnName = dataName;
  }

  icon(column: string) {
    if (this.columns[column] === 'asc') {
      return `../../assets/media/icons/duotune/arrows/arr003.svg`
    } else {
      return `../../assets/media/icons/duotune/arrows/arr004.svg`
    }
  }

}
