<div [formGroup]="parentForm">
  <input class="form-control form-control-solid " [formControlName]="formControlName" #flatpickr
    [placeholder]="placeholder" />
    <div class="fv-plugins-message-container" *ngIf="isInvalid()">
      <div class="fv-help-block">
        <span role="alert">
          {{"shared.flatpickr.campo-obbligatorio" | translate}}
        </span>
      </div>
    </div>
</div>
