import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "./auth.service";
import {SessionQuery} from "../../../shared/services/session/session.query";

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
  constructor(private authService: AuthService, private sessionQuery: SessionQuery, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.sessionQuery.getValue().token;
    if (!currentUser) {
      // logged in so return true
      return true;
    } else {
      this.router.navigateByUrl('/dashboard');
      return false;
    }
  }
}
