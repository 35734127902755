<div [formGroup]="parentForm" [attr.id]="formControlName" >
  <select class="form-select form-select-solid" #select2 [formControlName]="formControlName">
    <option *ngIf="emptyValue"></option>
    <option [value]="option?.value" *ngFor="let option of options">{{option?.label}}</option>
  </select>
  <div class="fv-plugins-message-container" *ngIf="isInvalid">
    <div class="fv-help-block">
      <span role="alert">
        {{"shared.select2.campo-obbligatorio" | translate}}
      </span>
    </div>
  </div>
</div>
