import { Component, OnInit, TemplateRef } from '@angular/core';
import { ModalService } from './modal.service';

export interface ModalData {
  template: TemplateRef<any>;
  title?: string;
  passedData?: any
  size?: 'md' | 'xl' | 'xxl'
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  public data!: ModalData;
  public template: ModalData['template'];
  public title: ModalData['title'];
  public passedData: ModalData['passedData'];
  public size: ModalData['size'] = 'md';

  constructor(
      private modalService: ModalService
  ) {}

  ngOnInit() {
    this.serviceSubscription();
  }

  serviceSubscription() {
    this.modalService.$open.subscribe(data => {
      console.log(data);
      this.passedData = data.passedData
      this.data = data;
      this.template = data.template;
      this.title = data.title;
      this.size = data.size;
    });
    this.modalService.$close.subscribe(() => this.data = null as any);
  }

  close() {
    document.body.classList.remove('overflow-hidden');
    this.modalService.close();
  }

}
