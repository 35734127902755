import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { ToastNotificationModel } from './toast.model';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public subject = new Subject<ToastNotificationModel>();
  public keepAfterRouteChange = true;

  constructor(public router: Router) {
    // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(message: string, keepAfterRouteChange = false) {
    this.showNotification('Success', message, keepAfterRouteChange);
  }

  error(message: string, keepAfterRouteChange = false) {
    this.showNotification('Error', message, keepAfterRouteChange);
  }

  info(message: string, keepAfterRouteChange = false) {
    this.showNotification('Info', message, keepAfterRouteChange);
  }

  warn(message: string, keepAfterRouteChange = false) {
    this.showNotification('Warning', message, keepAfterRouteChange);
  }

  showNotification(type: ToastNotificationModel['type'], message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({ type: type, message: message });
  }

  clear() {
    // this.subject.next();
    console.log('clear')
  }

}
