import { Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { PaginationModel } from 'src/app/shared/models/pagination/pagination.model';

@Component({
  selector: 'app-table-footer',
  templateUrl: './table-footer.component.html',
  styleUrls: ['./table-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFooterComponent {

  @Input() pagination: PaginationModel;
  @Input() show: boolean;
  @Output() loadSelectedPage = new EventEmitter<number>();

  constructor() { }

  loadMorePages(e: any){
    this.loadSelectedPage.emit(e)
  }

}
