import { PaginationModel } from '../../models/pagination/pagination.model';
import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { SortService } from './sort.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {

  @Input() pagination: PaginationModel;
  @Input() data: PaginationModel['data'];
  @Input() showTable: boolean;
  @Input() columns?: any;
  @Input() isSearching?: boolean;
  @Input() isLoading?: boolean;
  @Output() loadSelectedPage = new EventEmitter<number>();
  @Output() keyword = new EventEmitter<string>();

  constructor(public sortService: SortService) { }

  loadMorePages(e: any){
    this.loadSelectedPage.emit(e)
  }

  searchKeyword(e: string){
    this.keyword.emit(e);
  }


}
