import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environmentdemo } from '../../../../../environments/environment-demo';
import { routes } from '../../../../routes';
import { Register } from '../../models/register/register.model';
import { RegisterSucces } from '../../models/register/register-success.model';
import { AuthSuccess } from '../../models/auth/auth-success.model';

const API_USERS_URL = `${environmentdemo.apiUrl}/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<AuthSuccess | any> {
    const notFoundError = new Error('Not Found');
    if (!email || !password) {
      return of(notFoundError);
    }
    return this.http.post<AuthSuccess>(routes.auth.login(), { username: email, password }).pipe(
      map((res: AuthSuccess) => {
        console.log(res);
        const auth = res;
        return auth;
      })
    );
  }

  otp(email: string, otp: string): Observable<AuthSuccess | any> {
    const notFoundError = new Error('Not Found');
    if (!email || !otp) {
      return of(notFoundError);
    }
    return this.http.post<AuthSuccess>(routes.auth.otp(), { username: email, otp }).pipe(
      map((res: AuthSuccess) => {
        console.log(res);
        const auth = res;
        return auth;
      })
    );
  }

  logout(): Observable<any> {
    return this.http.post(routes.auth.logout(), {}).pipe(
      map((res) => {
        console.log('auht-http.service', res);
        return res
      })
    )
  }


  register(res: Register): Observable<RegisterSucces | any> {
    const notFoundError = new Error('Not Found');
    if (!res) {
      return of(notFoundError);
    }
    return this.http.post<RegisterSucces>(routes.auth.register(), res).pipe(
      map((res: RegisterSucces) => {
        console.log(res);
        const registration = res;
        return registration;
      }),

    );
  }

  verifyAccount(res: any): Observable<any>{
    const notFoundError = new Error('Not Found');
    if (!res) {
      return of(notFoundError);
    }
    return this.http.post<RegisterSucces>(routes.auth.verifyAccount(), res).pipe(
      map((res: RegisterSucces) => {
        console.log(res);
        const verify = res;
        return verify;
      }),

    );
  }

  forgotPassword(res: any, token?: string, password?: string, password_confirmation?: string): Observable<any>{
    const notFoundError = new Error('Not Found');
    if (!res) {
      return of(notFoundError);
    }
    return this.http.post<any>(routes.auth.passwordRecovery(), {username: res, token, password, password_confirmation}).pipe(
      map((res: any) => {
        return res
      }),

    );
  }

}
