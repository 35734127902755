import {Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import { FormGroup } from '@angular/forms';
import flatpickr from "flatpickr";
import { Italian } from "flatpickr/dist/l10n/it";
import moment from 'moment';
import { insertAfterElement } from 'src/app/_metronic/kt/_utils';
@Component({
  selector: 'app-flatpickr',
  templateUrl: './flatpickr.component.html',
  styleUrls: ['./flatpickr.component.scss']
})
export class FlatpickrComponent implements OnInit, OnChanges {

  @Input() placeholder: string;
  @Input() parentForm: FormGroup;
  @Input() formControlName: string;
  @Input() validators: any;
  @Input() maxDate?: any;
  @Input() minDate?: any;
  @ViewChild('flatpickr', { read: ElementRef, static: true }) flatpickrElement: ElementRef<any>;

  constructor() {}

  ngOnInit(): void {
    flatpickr(this.flatpickrElement.nativeElement, {
      locale: Italian,
      disableMobile: true,
      dateFormat: 'd-m-Y',
      defaultDate: this.parentForm.get(this.formControlName)?.value ? this.parentForm.get(this.formControlName)?.value : null,
      maxDate: this.maxDate ? this.maxDate : null,
      minDate: this.minDate ? this.minDate : null
    });
  }

  ngOnChanges(changes:SimpleChanges) {
    if (changes.maxDate) {
      flatpickr(this.flatpickrElement.nativeElement, {
        locale: Italian,
        disableMobile: true,
        dateFormat: 'd-m-Y',
        defaultDate: this.parentForm.get(this.formControlName)?.value ? this.parentForm.get(this.formControlName)?.value : null,
        maxDate: changes.maxDate.currentValue as any,
        minDate: this.minDate ? this.minDate : null
      });
    }
    if (changes.minDate) {
      flatpickr(this.flatpickrElement.nativeElement, {
        locale: Italian,
        disableMobile: true,
        dateFormat: 'd-m-Y',
        defaultDate: this.parentForm.get(this.formControlName)?.value ? this.parentForm.get(this.formControlName)?.value : null,
        maxDate: this.maxDate ? this.maxDate : null,
        minDate: changes.minDate.currentValue as any,
      });
    }
  }

  isInvalid() {
    const required = this.parentForm.controls[this.formControlName].errors;
    const touched = this.parentForm.controls[this.formControlName].touched;
    const value = this.parentForm.controls[this.formControlName].value;
    if (required && touched && !value) {
        return true
    }
    return false
  }

}
