<div [formGroup]="parentForm" class="d-flex flex-column gap-8">
  <div formArrayName="sections" *ngFor="let t of formSection?.controls; let i = index" class="bg-lighten p-8 rounded">
    <div [formGroupName]="i">
      <div class="mb-8 fs-3 fw-bolder d-flex justify-content-between">
        <div>
          <span *ngIf="t.get('additional')?.value">{{"shared.form-array.sezione-opzionale" | translate}}</span>
          <span *ngIf="t.get('additional')?.value && t.get('title')?.value"> • </span>
          <span *ngIf="t.get('title')?.value">{{t.get('title')?.value}}</span>
        </div>
        <!--<button class="btn btn-icon position-relative " *ngIf="(formSection?.controls && formSection.controls.length > 1) && canManage" placement="top-center" ngbTooltip="Elimina sezione"
                (click)="deleteSection(i)">
                <span [inlineSVG]="'../../assets/media/icons/duotune/general/gen027.svg'"
                      class="svg-icon svg-icon-2 svg-icon-2x text-danger"></span>
        </button>-->
      </div>
      <div class="d-flex flex-column gap-2">
        <div *ngIf="t.get('additional')?.value || t.get('unique')?.value">
          <label class="d-flex align-items-center fs-6 fw-bold mb-2 required">
            {{"shared.form-array.titolo" | translate}}
          </label>
          <input formControlName='title' id="{{'title'+i}}" type="text" class="form-control mb-4"
                 [placeholder]="this.translate.instant('shared.form-array.titolo.placeholder')"/>
        </div>
        <div>
          <label class="d-flex align-items-center fs-6 fw-bold mb-2 required">
            {{"shared.form-array.testo" | translate}}
          </label>
          <textarea autosize formControlName='text' id="{{'text' + i}}" type="number" class="form-control mb-4"
                    [placeholder]="this.translate.instant('shared.form-array.testo.placeholder')"></textarea>
        </div>
      </div>

    </div>
  </div>

  <!--<div class="rounded d-flex gap-3 justify-content-end" *ngIf="canManage">
    <button class="btn btn-light-primary" type="button" (click)='addSection()'>
      Aggiungi sezione
      <span class="svg-icon svg-icon-2 ms-2" [inlineSVG]="'./assets/media/icons/duotune/arrows/arr009.svg'"></span>
    </button>
  </div>-->
</div>

