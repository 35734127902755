import { ModalService } from './modal.service';
import { Directive, TemplateRef } from '@angular/core';
import { ModalData } from './modal.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-template',
  exportAs: 'modal'
})
export class ModalDirective {

  constructor(
    private modalService: ModalService,
    public templateRef: TemplateRef<any>
  ) { }

  open(title?: ModalData['title'], data?: any, size?: ModalData['size']) {
    document.body.classList.add('overflow-hidden');
    this.modalService.open({ template: this.templateRef, title: title, passedData: data , size: size},);
  }
  close() {
    document.body.classList.remove('overflow-hidden');
    this.modalService.close();
  }

}


