import { Component, Input, OnInit, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit{

  @Input() placeholder: string;
  @Input() validators: any;
  @Input() type: string;
  @Input() parentForm: FormGroup;
  @Input() formControlName: string;

  public visible = false;

  constructor(
    public translate: TranslateService
  ) {

  }

  ngOnInit(){
    this.type = 'password';
  }

  toggleVisibility() {
    this.visible = !this.visible
    if (this.type === 'password') {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }




}
