<ng-content select="[headerFilter]">
</ng-content>
<div class="card ">
  <app-table-header (keyword)="searchKeyword($event)" [isSearching]="isSearching">
    <ng-container headerSearch>
      <ng-content select="[headerSearch]">
      </ng-content>
    </ng-container>
    <ng-container headerButtons>
      <ng-content select="[headerButtons]">
      </ng-content>
    </ng-container>
  </app-table-header>
  <div class="card-body py-4 mx-4 mx-xxl-0 my-0 mb-sm-4 py-lg-0 overflow-scroll " *ngIf="showTable">
    <ng-content select="[title]">
    </ng-content>
    <div class="table-responsive">
      <table class="table align-middle table-row-dashed fs-6 gy-2" id="kt_table_users">
        <thead>
        <tr class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
          <ng-container *ngFor="let column of columns; first as isFirst; last as isLast">
            <th class="cursor-pointer " [appSort]="data" data-order="asc" [attr.data-name]="column.name" [style]="{'min-width': column.width + 'px'}"
                *ngIf="isFirst">
              <div class="d-flex align-items-center gap-2 h6 text-muted">{{column.name}}
                <span [inlineSVG]="sortService.icon(column)" class="svg-icon svg-icon-8 text-primary"
                      [ngClass]="{'d-none': sortService.columnName !== column.name, 'rotate180h': sortService.columnName === column.name}"></span>
              </div>
            </th>

            <th class="cursor-pointer" [appSort]="data" data-order="asc" [attr.data-name]="column.name" [style]="{'min-width': column.width + 'px'}"
                *ngIf="!isFirst && !isLast">
              <div class="d-flex align-items-center gap-2 h6 text-muted">{{column.name}}
                <span [inlineSVG]="sortService.icon(column)" class="svg-icon svg-icon-8 text-primary"
                      [ngClass]="{'d-none': sortService.columnName !== column.name, 'rotate180h': sortService.columnName === column.name}"></span>
              </div>
            </th>

            <th class="text-end" *ngIf="isLast" [style]="{'min-width': column.width + 'px'}">
              <div class="h6 text-muted">
                {{column.name}}
              </div>
            </th>
          </ng-container>
        </tr>
        </thead>
        <tbody class="text-gray-600 fw-bold">
        <ng-content select="[body]"></ng-content>
        </tbody>
      </table>
    </div>
    <ng-container *ngIf="!data?.length && !isLoading">
      <div class="w-100 bg-light p-4 rounded h-80px d-flex align-items-center justify-content-center">
        <h2 class="mb-0 fs-5 text-muted">{{"common.nessun-risultato" | translate}}</h2>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoading">
      <div class="w-100 bg-light p-4 rounded h-80px d-flex align-items-center justify-content-center">
        <h2 class="mb-0 fs-5 text-muted">{{"common.loading" | translate}}<span
          class="spinner-border spinner-border-sm align-middle ms-2"></span></h2>
      </div>
    </ng-container>
  </div>
  <app-table-footer [pagination]="pagination" [show]="showTable && !isLoading && !isSearching"
                    (loadSelectedPage)="loadMorePages($event)"></app-table-footer>

</div>
