import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {AuthService} from "../modules/auth";
import {SessionQuery} from "../shared/services/session/session.query";
import {SessionStore} from "../shared/services/session/session.store";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private sessionQuery: SessionQuery,
    private sessionStore: SessionStore,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.sessionStore.logout();
            this.router.navigate(['/auth/login'], {
              queryParams: {},
            });
            // this.authService.logout();
            // const data = this.sessionQuery.getValue();
            // console.log(data);
            // if (data.user) {
            //   this.authService.logout();
            //   setTimeout(() => {
            //     window.location.reload();
            //   }, 10);
            // }
            return;
          }
          if (err.status === 500) {
            this.router.navigate(['error/500']);
            return;
          }
          if (err.status === 403) {
            this.router.navigate(['error/403']);
            return;
          }
          return;
        }
      }));
  }

}
