import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appProfileImage]'
})
export class ProfileImageDirective {

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.objectFit = 'cover';
  }

}
