import {Component, Input, OnInit} from '@angular/core';
import {UserModel} from "../../models/user/user.model";
import moment from "moment";
import {SessionQuery} from "../../services/session/session.query";
import {Router} from "@angular/router";
import {PermissionPipe} from "../../pipes/permission.pipe";

@Component({
  selector: 'app-user-preview',
  templateUrl: './user-preview.component.html',
  styleUrls: ['./user-preview.component.scss']
})
export class UserPreviewComponent implements OnInit {

  constructor(
    private sessionQuery: SessionQuery,
    private router: Router,
    private permissionPipe: PermissionPipe
  ) { }

  @Input() user: UserModel;

  ngOnInit(): void {
  }

  getFormattedDate(date: any) {
    return moment(date).format('DD-MM-YYYY')
  }

  goToUserPage(user: UserModel) {
    if (this.sessionQuery.getValue().user?.id !== user.id) {
      if (this.permissionPipe.transform([`manage-users-show-role-${user.roles[0].id}`]) && user.is_praticable) {
        this.router.navigateByUrl('/account/' + user.id + '/dati-anagrafici');
      }
    } else {
      this.router.navigateByUrl('/il-mio-account');
    }
  }

}
