import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {UserModel} from '../../models/user/user.model';
import {SessionQuery} from "./session.query";
import {SessionStore} from "./session.store";

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  currentProfileImageSubject: BehaviorSubject<string>;

  constructor() {
    this.currentProfileImageSubject = new BehaviorSubject<string>('');
  }

}
