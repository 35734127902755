import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {PracticeModel} from "../../models/pratiche/practice.model";

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnChanges {

  @Input() pratica: PracticeModel | undefined;
  timeline: PracticeModel['steps'] | undefined = [];

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.timeline = this.pratica?.steps;
  }

  firstLetterUppercase(text: string) {
    let newText = this.replaceKeywordInSteps(text);
    return newText[0].toUpperCase() + newText.slice(1);
  }

  replaceKeywordInSteps(text: string) {
    let newText = ""
    if (this.pratica && text && text.includes("_PARERE_SPECIALISTICO_LABEL_")) {
      newText = text.replace("_PARERE_SPECIALISTICO_LABEL_", this.pratica?.sottotipologia_contratto?.parere_specialistico_label);
    } else if (this.pratica && text  && text.includes("_RELAZIONE_LABEL_")) {
      newText = text.replace("_RELAZIONE_LABEL_", this.pratica?.sottotipologia_contratto?.relazione_label);
    } else {
      newText = text;
    }
    return newText;
  }

}

