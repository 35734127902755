import {EventEmitter, Injectable} from '@angular/core';
import { ModalData } from './modal.component';


@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public $open: EventEmitter<ModalData> = new EventEmitter<ModalData>();
  public $close: EventEmitter<void> = new EventEmitter<void>();


  open(data: ModalData) {
    this.$open.emit(data);
  }

  close() {
    this.$close.emit();
  }
}
