<div class="d-flex align-items-center mt-2 mb-2" data-kt-password-meter-control="highlight">
  <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" [ngClass]="{'active': passwordScore >= 20}"></div>
  <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" [ngClass]="{'active': passwordScore >= 40}"></div>
  <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" [ngClass]="{'active': passwordScore >= 60}"></div>
  <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2" [ngClass]="{'active': passwordScore >= 80}"></div>
  <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px" [ngClass]="{'active': passwordScore >= 100}"></div>
</div>
<span class="text-muted">
 {{"shared.password-meter.requirements" | translate}}
</span>
