<div [formGroup]="parentForm">
  <div class="fv-row">
    <div class="d-flex flex-stack" >
      <div class="fw-bold me-8">
        <label class="fs-6 mb-2">{{label}}</label>
        <div class="fs-7 text-gray-400" [innerHTML]="description"></div>
      </div>
      <div class="d-flex align-items-center mt-4" [ngClass]="{'opacity-75': isDisabled}">
        <label class="form-check form-check-custom form-check-solid">
          <input class="form-check-input h-30px w-30px" type="checkbox" [formControlName]="formControlName" (change)="onCheckboxChange($event)"/>
        </label>
      </div>
    </div>
  </div>
</div>
