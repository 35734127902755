<div class="d-sm-flex flex-stack flex-wrap px-10 pb-6 pt-0 pt-sm-0" *ngIf="pagination && pagination.last_page > 1 && total">
  <div class="fs-6 fw-bold text-gray-700 mb-4 mb-sm-0 text-left d-none d-sm-inline">Mostra da {{from}} a {{to}} di {{total}}</div>

  <ul class="pagination justify-content-start justify-content-sm-start">
    <li class="page-item previous" (click)="prevPage()" [ngClass]="{
      'd-none': pagination && pagination.current_page === 1}">
      <a class="page-link cursor-pointer">
        <i class="previous"></i>
      </a>
    </li>

    <ng-container *ngFor="let item of nav" >
      <li class="page-item" [ngClass]="{
        'active': item === pagination?.current_page?.toString()}" (click)="pageClick(item)">
        <a class="page-link cursor-pointer"> {{item}} </a>
      </li>
    </ng-container>

    <li class="page-item next" (click)="nextPage()" [ngClass]="{
      'd-none': pagination && pagination.current_page === pagination.last_page}">
      <a class="page-link cursor-pointer">
        <i class="next"></i>
      </a>
    </li>
  </ul>
</div>
