import {UserService} from './../../../shared/services/user.service';
import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import {AuthService} from './auth.service';
import {SessionService} from "../../../shared/services/session/session.service";
import {SessionQuery} from "../../../shared/services/session/session.query";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private sessionQuery: SessionQuery) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.sessionQuery.getValue().token;
    if (currentUser) {
      // logged in so return true
      return true;
    } else {
      this.authService.logout();
      return false;
    }
  }
}
