<div class="bar"></div>
<div class="timeline">
  <div class="entry rounded mx-10 bg-light-primary border border-3 p-4" *ngFor="let event of timeline">
    <div class="mb-0 fs-6">{{event.pivot.created_at | date: 'dd-MM-yyyy HH:mm'}}</div>
    <div class="mb-0 fs-5 fw-bolder">{{firstLetterUppercase(event.des)}}</div>
    <hr *ngIf="event.des" class="h-2px w-100 dashed">
    <div>{{event.pivot.user?.nome}} {{event.pivot.user?.cognome}}</div>
    <small class="mb-0 fw-bolder">{{event.pivot.user?.role}}</small> <br>
  </div>
</div>
