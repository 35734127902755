import {TableModule} from './components/table/table.module';
import {ModalDirective} from 'src/app/shared/components/modal/modal.directive';
import {ModalComponent} from './components/modal/modal.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {PasswordMeterComponent} from './components/password-meter/password-meter.component';
import {RouterModule} from '@angular/router';
import {JsonFormComponent} from './components/json-form/json-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {Select2Component} from './components/select2/select2.component';
import {FlatpickrComponent} from './components/flatpickr/flatpickr.component';
import {CheckboxComponent} from './components/checkbox/checkbox.component';
import {PasswordInputComponent} from './components/password-input/password-input.component';
import {PaginatorComponent} from './components/table/components/paginator/paginator.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastComponent} from './components/toast/toast.component';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {DisabledDirective} from './directives/disabled.directive';
import {ProfileImageDirective} from './directives/profile-image.directive';
import { PermissionPipe } from './pipes/permission.pipe';
import { UserPreviewComponent } from './components/user-preview/user-preview.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { FormArrayGeneratorComponent } from './components/form-array-generator/form-array-generator.component';
import {AutosizeModule} from "ngx-autosize";
import {TranslateModule} from "@ngx-translate/core";
import { LogoComponent } from './components/logo/logo.component';

@NgModule({
    imports: [
        CommonModule,
        InlineSVGModule.forRoot(),
        TableModule,
        ReactiveFormsModule,
        NgbTooltipModule,
        RouterModule,
        AutosizeModule,
        TranslateModule,
    ],
  exports: [
    //Components ---------
    ModalComponent,
    ModalDirective,
    PasswordMeterComponent,
    JsonFormComponent,
    Select2Component,
    FlatpickrComponent,
    CheckboxComponent,
    PasswordInputComponent,
    ToastComponent,
    ProgressBarComponent,
    //Modules ---------
    NgbTooltipModule,
    TableModule,
    DisabledDirective,
    ProfileImageDirective,
    PermissionPipe,
    UserPreviewComponent,
    TimelineComponent,
    FormArrayGeneratorComponent,
    LogoComponent,
  ],
  declarations: [
    ModalComponent,
    ModalDirective,
    PasswordMeterComponent,
    JsonFormComponent,
    Select2Component,
    FlatpickrComponent,
    CheckboxComponent,
    PasswordInputComponent,
    ToastComponent,
    ProgressBarComponent,
    DisabledDirective,
    ProfileImageDirective,
    PermissionPipe,
    UserPreviewComponent,
    TimelineComponent,
    FormArrayGeneratorComponent,
    LogoComponent,
  ],
  providers: [
    PermissionPipe
  ]
})
export class SharedModule {
}
