import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators, FormGroup, Form } from '@angular/forms';
import { JsonFormControls } from './json-form.model';

@Injectable({
  providedIn: 'root'
})
export class JsonFormService {

  constructor() { }

  ConfirmPassword(form: FormGroup): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const password = form?.get('password')?.value;
      const confirmPassword = form?.get('password_confirmation')?.value;
      form?.get('password')?.valueChanges.subscribe((passwordValue: any) => {
        console.log(passwordValue)
        if (form?.get('password_confirmation')?.value) {
          form?.get('password_confirmation')?.setErrors({ 'ConfirmPassword': true })
        }
      })
      if (password !== confirmPassword) {
        return { 'ConfirmPassword': true }
      }
      return null

    }
  }

  where(
    target: any,
    operator: '<' | '>' | '<=' | '>=' | '==' | '!=',
    value: []
  ): boolean {
    let res: string[] = [];
    let visible = false;
    switch (operator) {
      case '<':
        // res = target < value;
        console.log('TODOOO <')
        break;

      case '>':
        // res = target > value;
        console.log('TODOOO >')
        break;

      case '<=':
        // res = target <= value;
        console.log('TODOOO <= ')
        break;

      case '>=':
        // res = target >= value;
        console.log('TODOOO >=')
        break;

      case '==':
        value?.forEach((v: any) => {
          if (v === target) {
            res.push('true')
          } else {
            res.push('false')
          }
        });
        if (res.includes('true')) {
          visible = true
        } else {
          visible = false
        }
        break;

      case '!=':
        value?.forEach((v: any) => {
          if (v != target) {
            res.push('true')
          } else {
            res.push('false')
          }
        });
        if (res.includes('false')) {
          visible = false
        } else {
          visible = true
        }
        break;
    }

    return visible
  }

  setValidators(control: JsonFormControls, form: FormGroup): Promise<any> {
    return new Promise((resolve) => {
      const validatorsToAdd: any = [];
      for (const validator of control.validators) {

        for (const [key, value] of Object.entries(validator)) {
          switch (key) {
            case 'min':
              validatorsToAdd.push(Validators.min(value));
              break;
            case 'max':
              validatorsToAdd.push(Validators.max(value));
              break;
            case 'required':
              if (value) {
                validatorsToAdd.push(Validators.required);
              }
              break;
            case 'requiredTrue':
              if (value) {
                validatorsToAdd.push(Validators.requiredTrue);
              }
              break;
            case 'email':
              if (value) {
                validatorsToAdd.push(Validators.email);
              }
              break;
            case 'minLength':
              validatorsToAdd.push(Validators.minLength(value));
              break;
            case 'maxLength':
              validatorsToAdd.push(Validators.maxLength(value));
              break;
            case 'pattern':
              validatorsToAdd.push(Validators.pattern(value));
              break;
            case 'nullValidator':
              if (value) {
                validatorsToAdd.push(Validators.nullValidator);
              }
              break;
            case 'ConfirmPassword':
              if (value) {
                validatorsToAdd.push(this.ConfirmPassword(form));
              }
              break;
            default:
              break;
          }
        }
      }
      resolve(validatorsToAdd);
    })
  }

}
