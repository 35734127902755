<div class="card-body py-4 mx-4 mx-xxl-0 my-0 my-sm-4 py-lg-0 overflow-scroll">
  <table class="table align-middle table-row-dashed fs-6 gy-2" id="kt_table_users">
    <thead>
      <tr class="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
        <ng-container *ngFor="let column of columns; first as isFirst; last as isLast">
          <th class="min-w-125px cursor-pointer" [appSort]="data" data-order="asc" [attr.data-name]="column.name"
            *ngIf="isFirst">
            <div class="d-flex align-items-center gap-2 h6">{{column.name}}
              <span [inlineSVG]="sortService.icon(column)" class="svg-icon svg-icon-8 text-primary"
                [ngClass]="{'d-none': sortService.columnName !== column.name, 'rotate180h': sortService.columnName === column.name}"></span>
            </div>
          </th>

          <th class="min-w-125px cursor-pointer" [appSort]="data" data-order="asc" [attr.data-name]="column.name"
            *ngIf="!isFirst && !isLast">
            <div class="d-flex align-items-center gap-2 h6">{{column.name}}
              <span [inlineSVG]="sortService.icon(column)" class="svg-icon svg-icon-8 text-primary"
                [ngClass]="{'d-none': sortService.columnName !== column.name, 'rotate180h': sortService.columnName === column.name}"></span>
            </div>
          </th>

          <th class="text-end min-w-100px" *ngIf="isLast">
            <div class="h6">
              {{column}}
            </div>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody class="text-gray-600 fw-bold">
      <ng-content select="rows"></ng-content>
    </tbody>
  </table>
</div>
