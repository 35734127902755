import { BehaviorSubject, Observable } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableHeaderService {

  resetSearchInput$: Observable<string>;
  resetSearchInputSubject: BehaviorSubject<string>;

  constructor() {
    this.resetSearchInputSubject = new BehaviorSubject<string>('');
    this.resetSearchInput$ = this.resetSearchInputSubject.asObservable();
  }

  public resetKeyword(): any {
    this.resetSearchInputSubject.next('');
  }

}
