import {Component, Input, OnInit} from '@angular/core';
import {ConfigService} from "../../services/config.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  logo: string;
  itLogo = '/assets/media/logos/itlogo.png';
  esLogo = '/assets/media/logos/eslogo.png';
  @Input() classes: string;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    switch (this.translate.currentLang) {
      case 'it':
        this.logo = this.itLogo;
        break
      case 'es':
        this.logo = this.esLogo;
        break
      default:
        this.logo = this.itLogo
        break
    }
  }

}
