<div class="card-header border-0 py-6 justify-content-start">
  <div class="card-title">
    <div class="d-flex align-items-center position-relative my-1">
      <ng-content select="[headerSearch]">
      </ng-content>
    </div>
  </div>
  <div class="card-toolbar">
    <div class="d-flex justify-content-end" data-kt-user-table-toolbar="base" >
      <ng-content select="[headerButtons]">
      </ng-content>
    </div>
  </div>
</div>
