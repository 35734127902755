import { Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnChanges {
  @Input() label: string;
  @Input() description: string;
  @Input() formControlName: string;
  @Input() parentForm: FormGroup;

  constructor(
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnChanges() {
    this.parentForm.get(this.formControlName)?.valueChanges.subscribe(res => {
      this.cdRef.detectChanges();
    })
  }

  get isDisabled() {
    return this.parentForm.get(this.formControlName)?.disabled
  }

  onCheckboxChange(event: any) {
    const value = event.target.checked;
    this.parentForm.get(this.formControlName)?.setValue(value)
  }

}
