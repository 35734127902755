import { EventEmitter, Injectable, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Select2Service {
  public $reset: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  resetAllFields(){
    this.$reset.emit();
  }

}
