import {Component, OnInit, OnChanges, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {PaginationModel} from '../../../../models/pagination/pagination.model';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges {

  @Input() pagination: PaginationModel;

  constructor() {
  }

  public nav: string[] | undefined = [];

  @Output() loadSelectedPage = new EventEmitter<number>();

  current_page: PaginationModel['current_page']
  from: PaginationModel['from'] | undefined
  to: PaginationModel['to'] | undefined
  total: PaginationModel['total'] | undefined
  last_page: PaginationModel['last_page'] | undefined
  loader: any;

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.pagination)
    this.loader = true;
    this.current_page = this.pagination?.current_page;
    this.from = this.pagination?.from
    this.to = this.pagination?.to
    this.total = this.pagination?.total
    this.last_page = this.pagination?.last_page

    if (this.pagination) {
      // this.setNav();
      this.nav = this.createPaginationArray()
      this.loader = false
    }

  }

  get isVisible() {
    if (this.pagination.data.length <= this.pagination.per_page) {
      return false
    }
    return true
  }



  setNav() {
    this.nav = [];

    const { current_page, last_page } = this.pagination;
    let res = '';


    for (let i = 1; i <= 10; i++) {
      switch (i) {
        case 1:
          this.nav.push(`1`);
          break;

        case 2:
          if (last_page >= 2) {
            this.nav.push(current_page > 4 ? `...` : '2');
          }
          break;

        case 3:
          if (last_page >= 3) {
            res = '';
            switch (true) {
              case current_page <= 3:
                res = '3';
                break;

              case current_page >= last_page - 3:
                res = `${last_page - 4}`;
                break;

              default:
                res = `${current_page - 1}`;
                break;
            }
            this.nav.push(res);
          }
          break;

        case 4:
          if (last_page >= 4) {
            res = '';
            switch (true) {
              case current_page <= 3:
                res = '4';
                break;

              case current_page >= last_page - 3:
                res = `${last_page - 3}`;
                break;

              default:
                res = `${current_page}`;
                break;
            }
            this.nav.push(res);
          }
          break;

        case 5:
          if (last_page >= 5) {
            res = '';
            switch (true) {
              case current_page <= 3:
                res = '5';
                break;

              case current_page >= last_page - 3:
                res = `${last_page - 2}`;
                break;

              default:
                res = `${current_page + 1}`;
                break;
            }
            this.nav.push(res);
          }
          break;

        case 6:
          if (last_page >= 6) {
            this.nav.push(
              current_page < last_page - 3 ? `...` : `${last_page - 1}`
            );
          }
          break;

        case 7:
          if (last_page >= 7) {
            this.nav.push(`${last_page}`);
          }
          break;

        default:
          break;
      }
    }
  }

  createPaginationArray(): string[] {
    this.nav = [];

    const { current_page, last_page } = this.pagination;
    const pagesToShow = 7;
    let paginationArray = [];

    if (last_page <= pagesToShow) {
      for (let i = 1; i <= last_page; i++) {
        paginationArray.push(i.toString());
      }
      return paginationArray;
    }

    let start = current_page - Math.floor(pagesToShow / 2);
    let end = current_page + Math.floor(pagesToShow / 2);

    if (start <= 1) {
      end += 1 - start;
      start = 1;
    }

    if (end >= last_page) {
      start -= end - last_page;
      end = last_page;
    }

    if (start > 1) {
      paginationArray.push("1");
      if (start > 2) {
        paginationArray.push("...");
      }
    }

    for (let i = start; i <= end; i++) {
      paginationArray.push(i.toString());
    }

    if (end < last_page) {
      if (end < last_page - 1) {
        paginationArray.push("...");
      }
      paginationArray.push(last_page.toString());
    }

    return paginationArray;
  }


  pageClick(value: string) {
    if (value != this.current_page.toString()) {
      if (!this.loader) {
        if (value === '...' || +value > this.pagination.last_page || +value < 1) {
          return;
        }

        if (value !== this.current_page.toString()) {
          this.loader = value;
        }

        this.emitEvent(+value)
      }
    }

  }

  prevPage() {
    if (!this.loader) {
      if (this.pagination.current_page <= 1) {
        return;
      }
      if (this.pagination.current_page - 1 !== this.current_page) {
        this.loader = this.pagination.current_page - 1;
      }
      this.emitEvent(this.pagination.current_page - 1)
    }
  }

  nextPage() {
    if (!this.loader) {
      if (this.pagination.current_page >= this.pagination.last_page) {
        return;
      }
      if (this.pagination.current_page + 1 !== this.current_page) {
        this.loader = this.pagination.current_page + 1;
      }
      this.emitEvent(this.pagination.current_page + 1)
    }
  }

  emitEvent(value: number) {
    this.loadSelectedPage.emit(value)
  }

}
