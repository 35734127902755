import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import 'zone.js';
import {persistState} from "@datorama/akita";
import {environment} from "./environments/environment";  // Included with Angular CLI.

if (environment.production) {
  enableProdMode();
  window.console.log = () => { };
  window.console.warn = () => { };
  window.console.error = () => { };
}

// akita persist state
const storage = persistState({ include: ['session']});
const providers = [{ provide: 'persistStorage', useValue: storage, }];

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
