<div (click)="$event.stopPropagation()" [ngClass]="{'show-modal' : data, 'hide-modal' : !data}">
  <div class="modal-dialog-centered m-0">
    <div class="modal-content mw-1000px mx-6 mx-md-0 min-md-w-100 "
      [ngClass]="{
      'min-md-w-500px max-md-w-750px': this.size === 'md',
      'min-md-w-750px max-md-w-1000px': this.size === 'xl',
      'min-md-w-1000px max-md-w-1000px': this.size === 'xxl'
      }">
      <div class="modal-header border border-2">
        <h1 class="mb-0 me-4 fs-2">
          {{title}}
        </h1>
        <button class="btn btn-sm btn-icon btn-active-color-primary" type="button" (click)="close()">
          <span [inlineSVG]="'../../assets/media/icons/duotune/arrows/arr011.svg'"
            class="svg-icon svg-icon-1"></span>
        </button>
      </div>
      <div class="modal-body scroll-y p-8 mh-500px mh-xxl-600px">
        <div class="mx-auto">
          <div class="fs-5 fw-bold text-gray-800">
            <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{data: data?.passedData}"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
