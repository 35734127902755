<div [formGroup]="parentForm">
  <div class="position-relative mb-3">
    <input class="form-control form-control-solid" [type]="type" [placeholder]="placeholder"
      [formControlName]="formControlName" />
    <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
      (click)="toggleVisibility()">
      <i class="bi bi-eye-slash fs-2" *ngIf="!visible"></i>
      <i class="bi bi-eye fs-2" *ngIf="visible"></i>
    </span>
  </div>
</div>
<ng-container *ngFor="let validator of validators">
  <ng-container *ngIf="validator.required" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
    validation: 'required',
    message: this.translate.instant('shared.password.campo-obbligatorio'),
    control: parentForm.controls[formControlName]
  }"></ng-container>
  <ng-container *ngIf="validator.pattern" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
      validation: 'pattern',
      message: this.translate.instant('shared.password.requirements'),
      control: parentForm.controls[formControlName]
    }"></ng-container>
    <ng-container *ngIf="validator.ConfirmPassword" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
      validation: 'ConfirmPassword',
      message: this.translate.instant('shared.password.non-corrisponde'),
      control: parentForm.controls[formControlName]
    }"></ng-container>
</ng-container>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control?.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">
          {{ message }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>
