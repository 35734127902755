import { TableHeaderService } from './table-header.service';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  @ViewChild('search', { static: false }) search: ElementRef;
  @Input() isSearching?: boolean;
  @Output() keyword = new EventEmitter<string>();
  isFiltered: boolean = false;
  inputValue: string;

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private tableHeaderService: TableHeaderService
  ) {
    // this.router.events.subscribe(res => {
    //   this.search.nativeElement.value = '';
    // })
  }

  ngOnInit(): void {
    this.tableHeaderService.resetSearchInput$.subscribe(() => {
      this.isFiltered = false;
      // this.search.nativeElement.value = '';
    });
  }

  searchKeyword(keyword?: string) {
    if (this.inputValue !== '' && this.inputValue !== undefined && this.inputValue !== null) {
      this.isFiltered = true;
      if (!keyword) {
        this.keyword.emit(this.inputValue)
      } else {
        this.reset()
      }
    }
  }

  setInputValue(event: any) {
    this.inputValue = event.target.value;
    if (this.inputValue === '' || this.inputValue === undefined || this.inputValue === null) {
      this.resetKeyword();
    }
  }

  resetKeyword() {
    this.isFiltered = false;
    this.reset()
    this.cdRef.markForCheck();
  }

  reset() {
    this.isFiltered = false;
    this.keyword.emit('reset')
    this.search.nativeElement.value = '';
  }


}
