import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { AuthService } from "../modules/auth";
import {SessionService} from "../shared/services/session/session.service";
import {SessionQuery} from "../shared/services/session/session.query";
import {SessionStore} from "../shared/services/session/session.store";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private sessionQuery: SessionQuery) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.sessionQuery?.getValue().token;
    if (authToken) {
      req = req.clone({
        setHeaders: {
          Accept: 'application/json',
          Authorization: "Bearer " + authToken
        }
      });
    } else {
      req = req.clone({
        setHeaders: {
          Accept: 'application/json',
        }
      });
    }
    return next.handle(req);
  }
}
