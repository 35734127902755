import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appDisabled]'
})
export class DisabledDirective implements OnChanges {
  @Input() appDisabled = false;

  constructor(private el: ElementRef) {


  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['appDisabled'] && changes['appDisabled'].currentValue) {
      this.el.nativeElement.style.opacity = 0.5;
      this.el.nativeElement.style.filter = 'grayscale(1)';
      this.el.nativeElement.style.cursor = 'not-allowed';
    }
  }
}
