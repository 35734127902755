import {Injectable} from '@angular/core';
import {BehaviorSubject, finalize, map, Observable, throwError} from 'rxjs';
import {UserModel} from '../../models/user/user.model';
import {UsersHTTPService} from '../users-http.service';
import {HttpClient} from "@angular/common/http";
import {PaginationModel} from "../../models/pagination/pagination.model";
import {routes} from "../../../routes";
import {blobToBase64} from "../../utils/base64.utils";
import {AuthSuccess} from "../../../modules/auth/models/auth/auth-success.model";
import {catchError} from "rxjs/operators";

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root'
})
export class SessionHTTPService {

  constructor(
    private http: HttpClient
  ) {
  }

  whoAmI(): Observable<AuthSuccess['me']> {
    return this.http.get<AuthSuccess['me']>(routes.session.me()).pipe(
      map((res: AuthSuccess['me']) => {
        const me = res;
        return me;
      }),
      catchError(err => {
        console.error(err)
        return throwError(err);
      }),
    );
  }

  profileImage(): Observable<Promise<string>> {
    return this.http
      .get(routes.profile.profileImage.get(), {
        headers: {
          Accept: `application/json`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        }, responseType: 'blob'
      })
      .pipe(
        map(async (res: Blob) => {
          const base64 = await blobToBase64(res) as string;
          return base64;
        }),
        catchError(err => {
          return throwError(err);
        }),
      )
  }

  // getSignature(): Observable<Promise<string>> {
  //   return this.http
  //     .get<any>(routes.il-mio-profilo.signature.get(), {
  //       headers: {
  //         Accept: `application/json`,
  //         'Cache-Control': 'no-cache',
  //         Pragma: 'no-cache',
  //         Expires: '0',
  //       }, responseType: 'blob'
  //     })
  //     .pipe(
  //       map(async (res: Blob) => {
  //         const base64 = await blobToBase64(res) as string;
  //         return base64;
  //       })
  //     );
  // }
}
