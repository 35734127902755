import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {UserType} from "./session.service";
import {BehaviorSubject} from "rxjs";
import {RoleModel} from "../../../modules/auth/models/auth/auth-success.model";
import {PracticeModel} from "../../models/pratiche/practice.model";
import {Alert} from "../../../_metronic/layout/components/content/content.component";
import {FormControl} from "@angular/forms";

export interface SessionState {
  token: string | null,
  user: UserType | null;
  role: RoleModel[];
  permissions: string[] | null;
  profileImage: string | null;
  alerts: Alert[] | null;
  praticheFilterForm: {
    status: string;
    key: string;
    started_criteria: string;
    expired_criteria: string;
    started_at: string;
    expired_at: string;
    order_by: string;
    saveForm: string;
  } | null;
}

export function createInitialState(): SessionState {
  return {
    token: null,
    user: null,
    role: [],
    permissions: null,
    profileImage: null,
    alerts: null,
    praticheFilterForm: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<SessionState> {

  currentProfileImage: BehaviorSubject<string>;

  constructor() {
    super(createInitialState());
    this.currentProfileImage = new BehaviorSubject<string>('');
  }

  setToken(token: string | null): void {
    // update local token
    this.update(state => {
      return { ...state, token };
    });
  }

  setUser(user: UserType): void {
    // update local user
    this.update(state => {
      return { ...state, user };
    });
  }

  setPermissions(permissions: string[]): void {
    // update local user
    this.update(state => {
      return { ...state, permissions };
    });
  }

  setRole(role: RoleModel[]): void {
    // update local user
    this.update(state => {
      return { ...state, role };
    });
  }

  setProfileImage(profileImage: string): void {
    this.currentProfileImage.next(profileImage);
    // update local user
    this.update(state => {
      return { ...state, profileImage };
    });
  }

  setAlert(alerts: Alert[]): void {
    // update local alerts
    this.update(state => {
      return { ...state, alerts };
    });
  }

  setPraticheFilterPreferences(praticheFilterForm: {
    status: string;
    key: string;
    started_criteria: string;
    expired_criteria: string;
    started_at: string;
    expired_at: string;
    order_by: string;
    saveForm: string;
  }): void {
    // update local token
    this.update(state => {
      return { ...state, praticheFilterForm };
    });
  }

  logout(): void {
    // update local user
    this.update(state => {
      return { ...state, user: null, token: null, profileImage: null, permissions: null, role: [], pratiche: [], alerts: null, praticheFilterForm: null };
    });
  }

}
