import {Injectable, OnDestroy} from '@angular/core';
import {Observable, BehaviorSubject, Subscription} from 'rxjs';
import {map, finalize} from 'rxjs/operators';
import {AuthHTTPService} from './auth-http';
import {Router} from '@angular/router';
import {Register} from '../models/register/register.model';
import {AuthSuccess} from '../models/auth/auth-success.model';
import {VerifyAccountModel} from '../models/verify-account/verify-account.model';
import {VerifyAccountSuccessModel} from '../models/verify-account/verify-account-success.model';
import {UserModel} from 'src/app/shared/models/user/user.model';
import {SessionService} from "../../../shared/services/session/session.service";
import {SessionHTTPService} from "../../../shared/services/session/session-http.service";
import {SessionStore} from "../../../shared/services/session/session.store";
import {SessionQuery} from "../../../shared/services/session/session.query";

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  private authLocalStorageToken = `SMLtoken`;

  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private sessionService: SessionService,
    private sessionStore: SessionStore,
    private sessionQuery: SessionQuery
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();

  }

  getToken(): string | undefined {
    const token = this.sessionQuery.getValue().token
    if (!token) {
      return;
    }
    return token;
  }

  // TODO: Verifica che il modella della risposta sia corretto
  login(email: string, password: string): Observable<UserType | any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map(() => {
        // const result = this.setAuthFromLocalStorage(auth);
        // this.sessionStore.setUser(auth.me.user);
        // this.sessionStore.setPermissions(auth.me.permissions);
        // this.sessionStore.setRole(auth.me.roles);
        // this.sessionStore.setPratiche(auth.me.pratiche);
        return email;
      }),
      finalize(() => {
        this.isLoadingSubject.next(false);
      })
    );
  }

  otp(email: string, otp: string): Observable<UserType | any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.otp(email, otp).pipe(
      map((auth: AuthSuccess) => {
        const result = this.setAuthFromLocalStorage(auth);
        this.sessionStore.setUser(auth.me.user);
        this.sessionStore.setPermissions(auth.me.permissions);
        this.sessionStore.setRole(auth.me.roles);
        this.sessionStore.setAlert(auth.me.alerts);
        return result;
      }),
      finalize(() => {
        setTimeout(() => {
          this.isLoadingSubject.next(false);
        }, 1000)
      })
    );
  }

  private setAuthFromLocalStorage(auth: AuthSuccess): any {
    if (auth && auth.token) {
      this.sessionStore.setToken(auth.token);
      // localStorage.setItem(this.authLocalStorageToken, auth.token);
      return true;
    }
    return false;
  }

  logout() {
    this.isLoadingSubject.next(true);
    return this.authHttpService.logout().subscribe({
      next: (res) => {
        this.sessionStore.logout();
        this.isLoadingSubject.next(false);
        this.router.navigate(['/auth/login'], {
          queryParams: {},
        }).then(() => {
          setTimeout(() => {
            location.reload();
          }, 1000);
        });
      },
      error: (err) => {
        this.isLoadingSubject.next(false)
        this.router.navigate(['/auth/login'], {
          queryParams: {},
        }).then(() => {
          setTimeout(() => {
            location.reload();
          }, 1000);
        });
      }
    })
  }

  // TODO: Verifica che il modella della risposta sia corretto
  register(res: Register): Observable<Register | any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.register(res).pipe(
      map((res) => {
        if (res) {
          this.isLoadingSubject.next(false);
          return res;
        } else {
          this.isLoadingSubject.next(false);
          return false;
        }
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // TODO: Verifica che il modella della risposta sia corretto
  verifyAccount(object: VerifyAccountModel): Observable<VerifyAccountSuccessModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.verifyAccount(object).pipe(
      map((res) => {
        if (res) {
          this.isLoadingSubject.next(false);
          return res;
        } else {
          this.isLoadingSubject.next(false);
          return false;
        }
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string, token?: string, password?: string, password_confirmation?: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email, token, password, password_confirmation)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
