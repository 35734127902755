import { SortService } from '../../sort.service';
import { Component, Input, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-table-body',
  templateUrl: './table-body.component.html',
  styleUrls: ['./table-body.component.scss']
})
export class TableBodyComponent implements OnInit {

  @Input() data?: any;
  @Input() columns?: any;

  constructor(public sortService: SortService, private element: ElementRef) {
  }

  ngOnInit(): void {
    console.log(this.data);
  }
}
