import {Pipe, PipeTransform} from '@angular/core';
import {SessionService} from "../services/session/session.service";
import {SessionQuery} from "../services/session/session.query";

@Pipe({
  name: 'permission',
  pure: false
})
export class PermissionPipe implements PipeTransform {

  constructor(
    private sessionQuery: SessionQuery,
  ) {
  }

  transform(permission?: string[]): unknown {
    const userPermissions = this.sessionQuery.getValue().permissions;
    if (permission) {
      let result = false;
      permission.forEach((p: string) => {
        if (userPermissions?.includes(p) || userPermissions?.includes('do-anything')) {
          result = true;
        }
      })
      return result;
    } else {
      return true;
    }

  }

}
