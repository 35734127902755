import { SortService } from './sort.service';
import { Directive, Input, Renderer2, ElementRef, HostListener, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Sort } from './sort';

@Directive({
  selector: '[appSort]'
})
export class SortDirective{

  @Input() appSort: Array<any>;
  public previousOrder: string;

  constructor(private renderer: Renderer2, private targetElem: ElementRef, private sortService: SortService) { }

  // @HostListener('click')

  sortData() {
    const sort = new Sort();

    const elem = this.targetElem.nativeElement;

    // console.log('previous ==>> ',this.previousOrder)

    const order = elem.getAttribute("data-order");

    const type = elem.getAttribute("data-type");

    const property = elem.getAttribute("data-name");

    this.previousOrder = order;

    if (order === "desc") {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute("data-order", "asc");
      this.setIcon('asc', property);
    } else {
      this.appSort.sort(sort.startSort(property, order, type));
      elem.setAttribute("data-order", "desc");
      this.setIcon('desc', property);
    }

  }

  setIcon(dataOrder: "asc" | "desc", dataName: string){
    this.sortService.setIconStatus(dataOrder, dataName);
    this.sortService.setIconColumn(dataName);
  }


}
