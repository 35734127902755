<div class="d-flex flex-wrap flex-sm-nowrap gap-7">
  <div class="symbol symbol-fixed position-relative">
    <img [src]="user.b64_profile_image ? user.b64_profile_image : './assets/media/svg/avatars/blank.svg'"
          class="h-100px w-100px"/>
  </div>

  <div class="flex-grow-1">
    <div class="
            h-100
            d-flex
            justify-content-between
            align-items-start
            flex-wrap
            mb-2
          ">
      <div class="d-flex flex-column justify-content-start h-100" *ngIf="user">
        <div class="d-flex align-items-center" (click)="goToUserPage(user)">
          <a class="
          text-hover-primary
                  text-gray-800
                  fs-3
                  fw-bolder
                  me-1
                  cursor-pointer
                ">
            {{user?.nome}} {{user?.cognome}}
            <span *ngIf="user?.cod_formagiuridica !== 'PRIV' && user.label"> • {{user.label}}</span>
          </a>

        </div>

        <!-- PHONE -->
        <div class="d-flex flex-wrap fw-bold fs-6 pe-2 mt-2" *ngIf="user?.phone">
          <a class=" d-flex align-items-center text-gray-400 gap-1">
                <span [inlineSVG]="'./assets/media/icons/duotune/communication/com004.svg'"
                      class="svg-icon svg-icon-4 me-1"></span>
            <span *ngIf="user?.phone">{{user?.phone}}</span>
          </a>
        </div>

        <!-- USERNAME -->
        <div class="d-flex flex-wrap fw-bold fs-6 pe-2 mt-2"
             *ngIf="user?.username">
          <a class=" d-flex align-items-center text-gray-400 gap-1">
                <span [inlineSVG]="'./assets/media/icons/duotune/communication/com002.svg'"
                      class="svg-icon svg-icon-4 me-1"></span>
            <span>{{user.username}}</span>
          </a>
        </div>

        <!-- FORMA GIURIDICA -->
        <div class="d-flex flex-wrap fw-bold fs-6 pe-2 mt-2" *ngIf="user?.formagiuridica?.des">
          <a class=" d-flex align-items-center text-gray-400 gap-1">
            <span [inlineSVG]="'./assets/media/icons/duotune/abstract/abs020.svg'" class="svg-icon svg-icon-4 me-1"></span>
            <span>{{user?.formagiuridica?.des}}</span>
            <span *ngIf="user?.formagiuridica?.cod !== 'PRIV'"> • {{user?.label}}</span>
          </a>
        </div>

      </div>

    </div>
  </div>

</div>
