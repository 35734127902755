import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableComponent } from './table.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TableBodyComponent } from './components/table-body/table-body.component';
import { TableFooterComponent } from './components/table-footer/table-footer.component';
import { SortDirective } from './sort.directive';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginatorComponent } from './components/paginator/paginator.component';
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [TableComponent, TableHeaderComponent, TableBodyComponent, TableFooterComponent, SortDirective, PaginatorComponent],
  exports: [TableComponent, TableHeaderComponent, SortDirective, PaginatorComponent ],
    imports: [
        CommonModule,
        InlineSVGModule.forRoot(),
        NgbTooltipModule,
        RouterModule,
        TranslateModule,
    ]
})
export class TableModule { }
