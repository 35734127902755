<form [formGroup]="myForm" (ngSubmit)="onSubmit()">
  <span *ngFor="let section of jsonFormData?.layout; first as isFirst">
    <div class="row" *ngIf="section.visible !== false">

      <ng-container *ngFor="let rs of section?.rowSeparator">
        <div class="mb-10 d-flex" *ngIf="separatorIsVisible(rs)" [ngClass]="{'mt-2': isFirst, 'mt-6': !isFirst}">
          <span class="fs-2 fw-bold text-nowrap">
            {{rs.separator?.title}}
          </span>
          <hr class="h-2px w-100 ms-6 dashed">
        </div>
      </ng-container>

      <ng-container *ngFor="let field of section.controls">
        <div class="col-md-{{field.column}} fv-row" *ngIf="field.visible">
          <div class="d-flex flex-column mb-8 fv-row">
            <!--INPUT TEXT | EMAIL-->
            <div *ngIf="['text', 'email', 'number', 'search', 'tel', 'url'].includes(field.type)">
              <label class="d-flex align-items-center fs-6 fw-bold mb-2"
                [ngClass]="{'required': field.requiredAsterisk}">
                {{field.label}}
              </label>
              <input class="form-control form-control-solid" [type]="field.type" [placeholder]="field.placeholder"
                [formControlName]="field.name" [name]="field.name" />
              <ng-container *ngFor="let validator of field.validators">
                <ng-container *ngIf="validator.required" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                  validation: 'required',
                  message: this.translate.instant('shared.json-form.campo-obbligatorio'),
                  control: myForm.controls[field.name]
                }"></ng-container>
                <ng-container *ngIf="validator.pattern" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'pattern',
                    message: this.translate.instant('shared.json-form.valore-valido'),
                    control: myForm.controls[field.name]
                  }"></ng-container>
                <ng-container *ngIf="validator.email" [ngTemplateOutlet]="formError" [ngTemplateOutletContext]="{
                    validation: 'email',
                    message: this.translate.instant('shared.json-form.valore-valido'),
                    control: myForm.controls[field.name]
                  }"></ng-container>
              </ng-container>
              <div class="fv-plugins-message-container" *ngIf="serverErrors && serverErrors[field.name]">
                <div class="fv-help-block" *ngFor="let err of serverErrors[field.name]">
                  <span role="alert">
                    {{err}}
                  </span>
                </div>
              </div>
            </div>

            <!--PASSWORD-->
            <div *ngIf="['password'].includes(field.type)">
              <label class="d-flex align-items-center fs-6 fw-bold mb-2"
                [ngClass]="{'required': field.requiredAsterisk}">
                {{field.label}}
              </label>
              <app-password-input [placeholder]="field.placeholder" [type]="field.type" [parentForm]="myForm"
                [formControlName]="field.name" [validators]="field.validators" ngDefaultControl></app-password-input>
              <div class="fv-plugins-message-container" *ngIf="serverErrors && serverErrors[field.name]">
                <div class="fv-help-block" *ngFor="let err of serverErrors[field.name]">
                  <span role="alert">
                    {{err}}
                  </span>
                </div>
              </div>
            </div>


            <!-- SELECT -->
            <div *ngIf="['select', 'select-search', 'multiple-select'].includes(field.type)">
              <label class="d-flex align-items-center fs-6 fw-bold mb-2"
                [ngClass]="{'required': field.requiredAsterisk}">
                {{field.label}}
              </label>
              <app-select2 [placeholder]="field.placeholder" [type]="field.type" [parentForm]="myForm"  [allowClear]="field.resettable"
                [options]="field.options" [formControlName]="field.name" [validators]="field.validators"
                ngDefaultControl>
              </app-select2>
              <div class="fv-plugins-message-container" *ngIf="serverErrors && serverErrors[field.name]">
                <div class="fv-help-block" *ngFor="let err of serverErrors[field.name]">
                  <span role="alert">
                    {{err}}
                  </span>
                </div>
              </div>
            </div>

            <!-- DATEPICKER -->
            <div *ngIf="['datepicker'].includes(field.type)">
              <label class="d-flex align-items-center fs-6 fw-bold mb-2"
                [ngClass]="{'required': field.requiredAsterisk}">
                {{field.label}}
              </label>
              <app-flatpickr [placeholder]="field.placeholder" [parentForm]="myForm" [formControlName]="field.name"
                [validators]="field.validators" ngDefaultControl>
              </app-flatpickr>
              <div class="fv-plugins-message-container" *ngIf="serverErrors && serverErrors[field.name]">
                <div class="fv-help-block" *ngFor="let err of serverErrors[field.name]">
                  <span role="alert">
                    {{err}}
                  </span>
                </div>
              </div>
            </div>

            <!-- CHECKBOX -->
            <div *ngIf="['checkbox'].includes(field.type)">
              <app-checkbox [label]="field.label" [description]="field.placeholder" [formControlName]="field.name"
                [parentForm]="myForm" ngDefaultControl></app-checkbox>
              <div class="fv-plugins-message-container" *ngIf="serverErrors && serverErrors[field.name]">
                <div class="fv-help-block" *ngFor="let err of serverErrors[field.name]">
                  <span role="alert">
                    {{err}}
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </ng-container>
    </div>
  </span>
  <div class="d-flex justify-content-end">
    <div *ngFor="let button of jsonFormData?.buttons">
      <button class="btn btn-light me-3" type="reset" *ngIf="['reset'].includes(button.type)"
        (click)="resetForm()">{{button.label}}</button>
      <button type="submit" class="btn btn-primary" *ngIf="['submit'].includes(button.type)"
        [disabled]="myForm.invalid">
        <!--<button type="submit" class="btn btn-primary" *ngIf="['submit'].includes(button.type)">-->
        <span class="indicator-label" *ngIf="!submitLoading">{{button.label}}</span>
        <span class="indicator-label" *ngIf="submitLoading">Sto caricando...
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
        <span [inlineSVG]="button.iconPath" class="svg-icon svg-icon-2 me-0 ms-2" *ngIf="button.iconPath"></span>
      </button>
    </div>
  </div>
</form>

<ng-template #formError let-control="control" let-message="message" let-validation="validation">
  <ng-container *ngIf="control?.hasError(validation) && (control.dirty || control.touched)">
    <div class="fv-plugins-message-container">
      <div class="fv-help-block">
        <span role="alert">
          {{ message }}
        </span>
      </div>
    </div>
  </ng-container>
</ng-template>
