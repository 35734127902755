import {SessionService} from "../shared/services/session/session.service";
import {SessionHTTPService} from "../shared/services/session/session-http.service";
import {UserModel} from "../shared/models/user/user.model";
import {AuthSuccess} from "../modules/auth/models/auth/auth-success.model";
import {zip} from "rxjs";
import {SessionQuery} from "../shared/services/session/session.query";
import {SessionStore} from "../shared/services/session/session.store";
import {ConfigService} from "../shared/services/config.service";

export function appInitializer(sessionService: SessionService, sessionHTTPService: SessionHTTPService, sessionStore: SessionStore, sessionQuery: SessionQuery, configService: ConfigService): () => Promise<any> {
  return () =>
    new Promise((resolve) => {
      configService.initAppSettings();
      sessionStore.setProfileImage('/assets/media/svg/avatars/blank.svg');
      // if (sessionQuery.getValue().token) {
      //   sessionHTTPService.whoAmI().subscribe({
      //     next: (me: AuthSuccess['me']) => {
      //       sessionService.setCurrentUserValue(me.user);
      //       sessionService.setCurrentUserPermissions(me.permissions);
      //             resolve(true);
      //     },
      //     error: () => {
      //       resolve(true);
      //     }
      //   })
      // } else {
      //   resolve(true)
      // }

      if (sessionQuery.getValue().token) {
        sessionHTTPService.whoAmI()
          .subscribe((me: AuthSuccess['me']) => {
            if (me) {
              console.log(me.user);
              // sessionService.store.setUser(me.user);
              sessionStore.setUser(me.user);
              sessionStore.setPermissions(me.permissions);
              sessionStore.setRole(me.roles);
              sessionStore.setAlert(me.alerts);
            }
          })
          .add(resolve(true));
      } else {
        resolve(true)
      }
    });
}
