import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-password-meter',
  templateUrl: './password-meter.component.html',
  styleUrls: ['./password-meter.component.scss']
})
export class PasswordMeterComponent implements OnChanges {

  @Input() passwordToCheck: string | HTMLInputElement;
  passwordScore: number;

  constructor() { }

  private measureStrength(pass: string) {
    let score = 0;

    let letters: any = {};
    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    let variations: any = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };
    let variationCount = 0;
    for (let check in variations) {
      variationCount += (variations[check]) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    this.passwordScore = Math.trunc(score);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {

    var password = changes['passwordToCheck'].currentValue;
    if (password) {
      this.measureStrength(password);
    }
  }

}
